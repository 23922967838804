import React from 'react'
import ContactUs from '../../component/contactComponent/contact'
import HomePage from '../../component/homePageComponent/homePage'

export default function Home() {
  return (
    <div>
     
      <HomePage/>
      <ContactUs/>

    </div>
  )
}
